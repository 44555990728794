<template>
  <div class="mould">
    <van-nav-bar
        title="选择模板"
        left-text="返回"
        left-arrow
        fixed
        @click-left="onClickLeft"
    />
    <van-list
        v-model="loading"
        :finished="finished"
        error-text="请求失败，点击重新加载"
        :error.sync="error"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <van-search
          v-model="craftName"
          placeholder="请输入工艺路线名称"
          @search="onSearch"
          clearable
          @clear="onClear"
          @blur="onBlur"
      />
      <div class="mould_content">
        <div
            class="mould_content_item"
            @click="toCreateWork(item)"
            v-for="item in ewoMouldList"
            :key="item.id"
        >
          <div class="mould_content_item_name">
            <div class="item_name">{{ item.craftName }}</div>
            <!-- <div class="all_mould">（共{{item.craftItemList.length}}道工序）</div> -->
          </div>
          <div class="mould_content_item_all">
            <span>{{ item.craftItemTexts }}</span>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import {ChangePageTitle} from "../../common/js/upTitle";
import {mapGetters, mapActions} from "vuex";
import {getEwoMouldList} from "@/api/procedure";

export default {
  computed: {
    ...mapGetters(["departmentCode"]),
  },
  data() {
    return {
      value: "",
      ewoMouldList: [], //工艺集合
      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: 10,
      error: false,
      craftName: "",
    };
  },
  created() {
    ChangePageTitle("选择模板");
  },
  methods: {
    ...mapActions(['procedure/setNewProcedureList']),
    onClickLeft() {
      this.$router.push("/craft");
    },
    onSearch(value) {
      this.craftName = value;
      this.pageIndex = 1;
      this.ewoMouldList = [];
      this.onLoad();
    },
    onClear() {
      this.pageIndex = 1;
      this.ewoMouldList = [];
      this.onLoad();
    },
    onBlur() {
      if (this.craftName == "") {
        this.pageIndex = 1;
        this.ewoMouldList = [];
        this.onLoad();
      }
    },
    async onLoad() {
      try {
        const res = await getEwoMouldList({
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          keyword: null,
          departmentCode: this["departmentCode"],
          craftName: this.craftName
        });
        this.ewoMouldList.push(...res.records);
        this.loading = false;
        if (this.pageIndex * 10 < res.total) {
          this.pageIndex = res.pageIndex + 1;
        } else {
          this.finished = true;
        }
      } catch (err) {
        // 展示错误提示状态
        this.error = true;
        // 请求失败了，loading 也需要关闭
        this.loading = false;
      }
    },
    toCreateWork(list) {
      var newProcedureList = [];
      for (let i = 0; i < list.craftItemList.length; i++) {
        newProcedureList.push({
          id: list.craftItemList[i],
          wpName: list.craftItemTexts.split(",")[i],
        });
      }
      this['procedure/setNewProcedureList'](newProcedureList)
      this.$router.push({
        name: "/craft",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mould {
  width: 100vw;
  height: 100vh;
  background-color: #f4f4f4;

  .van-search {
    width: 100%;
    position: fixed;
    top: 85px;
    left: 0;
    z-index: 100;
    background-color: #f4f4f4;

    .van-search__content {
      background-color: #fff;
      border-radius: 5px;
    }
  }

  .mould_content {
    padding-top: 160px;
    width: 100vw;

    .mould_content_item {
      width: 95%;
      margin: auto;
      margin-top: 30px;
      padding: 20px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 10px;
      font-size: 30px;

      .mould_content_item_name {
        display: flex;

        .item_name {
          color: black;
        }

        .all_mould {
          margin-left: 5px;
        }
      }

      .mould_content_item_all {
        margin-top: 20px;
        font-size: 30px;
        line-height: 1.5;

        span {
          margin-left: 2px;
        }
      }
    }
  }
}
</style>
